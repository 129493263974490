import React from 'react'

const Team = () => {
  return (
    <div>
            <h1>Watch out for this space(our team)</h1>
      <h3>Tenant Care is coming</h3>
    </div>
  )
}

export default Team