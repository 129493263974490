import React from 'react'

const Event = () => {
  return (
    <div>
            <h1>Watch out for this space(Events)</h1>
      <h3>Tenant Care is coming</h3>

    </div>
  )
}

export default Event