import React from 'react'

const About = () => {
  return (
    <div>
      <h1>Watch out for this space(Abouts)</h1>
      <h3>Tenant Care is coming</h3>
    </div>
  )
}

export default About