import React from 'react'
import Demo from '../components/homefiles/Demo'
import Footer from './Footer'


const Home = () => {
  return (
    <div>
        <Demo />
        <Footer />
    </div>
  )
}

export default Home