import React from 'react'
import GlitchText from 'react-glitch-effect/core/GlitchText';
import "./home.css"

const Demo = () => {
 
  return (
    <div className='bg-image'>
      <div className="cent">
<GlitchText component='h1'>
    #1 Platform for Tenants Management and Care Supports Delivery in Nigeria
  </GlitchText>
      </div>
    </div>
  )
}

export default Demo