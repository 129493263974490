import React from 'react'

const RentalOrder = () => {
  return (
    <div>
      
      <h1>Watch out for this space (Booking page)</h1>
      <h3>Tenant Care is coming</h3>

    </div>
  )
}

export default RentalOrder