import React from 'react'

const SignUp = () => {
  return (
    <div>
            <h1>Watch out for this space (Signup-page)</h1>
      <h3>Tenant Care is coming</h3>

    </div>
  )
}

export default SignUp